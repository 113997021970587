@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #181D29;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1 {
    font-size: 32px;
}

@font-face {
    font-family: 'Brandon Grotesque, Black';
    src: url(https:/raw.githubusercontent.com/Celendi/Brand-Kit/blob/main/Fonts/brandon-grotesque-black.otf?raw=true);
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: transparant;
}

::-webkit-scrollbar-thumb {
    background: #1d2331;
    opacity: 0.5;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #242a36;
}